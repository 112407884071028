import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'dlx-grid-item-count',
  standalone: true,
  imports: [CommonModule],
  template: '{{!isMobile ? "Viser" : ""}} {{current}} av {{total}}'
})
export class GridItemCountComponent {
  @Input() isMobile: boolean = false;
  @Input() current: number = 0;
  @Input() total: number = 0;
}
